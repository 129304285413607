/* tslint:disable */
export enum CodeTables {
  ActionType = 'ActionType',
  AddressType = 'AddressType',
  ApprovalType = 'ApprovalType',
  AssessmentStatusType = 'AssessmentStatusType',
  AssistanceType = 'AssistanceType',
  AttendanceResultType = 'AttendanceResultType',
  AuditLogType = 'AuditLogType',
  BusinessType = 'BusinessType',
  CaseRoleType = 'CaseRoleType',
  CaseStatusType = 'CaseStatusType',
  ClaimType = 'ClaimType',
  ClaimStatusType = 'ClaimStatusType',
  ClientSatisfaction = 'ClientSatisfaction',  
  ClosureReasonType = 'ClosureReasonType',
  ContactByType = 'ContactByType',
  ContactResultType = 'ContactResultType',
  ContactType = 'ContactType',
  ContractType = 'ContractType',
  DesignationType = 'DesignationType',
  DisabilityType = 'DisabilityType',
  DocumentSection = 'DocumentSection',
  DocumentCategory = 'DocumentCategory',
  DocumentSubCategory = 'DocumentSubCategory',
  DocumentType = 'DocumentType',
  EapOutcomeType = 'EapOutcomeType',
  EapGoalCodeType = 'EapGoalCodeType',
  EapStatusType = 'EapStatusType',
  SolgenReferralType = 'SolgenReferralType',
  ElementType = 'ElementType',
  EmployeePlacementType = "EmployeePlacementType",
  EmployeeStatusType = 'EmployeeStatusType',
  EmployerSizeType = 'EmployerSizeType',
  EmploymentStatusType = 'EmploymentStatusType',
  EthnicityType = 'EthnicityType',
  ExpenditureStatusType = 'ExpenditureStatusType',
  ExpenditureType = 'ExpenditureType',
  ExitReasonType = 'ExitReasonType',
  GenderType = 'GenderType',
  IdType = 'IdType',
  IncomeSourceType = 'IncomeSourceType',
  IncentiveType = 'IncentiveType',
  InclusionGroupType = 'InclusionGroupType',
  InquirySourceType = 'InquirySourceType',
  InquiryStatusType = 'InquiryStatusType',
  IntakeSource = 'IntakeSource',
  IntegrationErrorType = 'IntegrationErrorType',
  SubIntakeSource = 'SubIntakeSource',
  LanguageType = 'LanguageType',
  LogSubType = 'LogSubType',
  LogType = 'LogType',
  MaritalStatusType = 'MaritalStatusType',
  MbmAssessmentType = 'MbmAssessmentType',
  MeetingCategoryType = 'MeetingCategoryType',
  MeetingLocationType = 'MeetingLocationType',
  MeetingRecurrenceType = 'MeetingRecurrenceType',
  MeetingStatusType = 'MeetingStatusType',
  MeetingSubjectType = 'MeetingSubjectType',
  MeetingType = 'MeetingType',
  Module = 'Module',
  MonitoringPhaseType = 'MonitoringPhaseType',
  NaicsType = 'NaicsType',
  NocType = 'NocType',
  OutcomeResultType = 'OutcomeResultType',
  OutcomeStatusType = 'OutcomeStatusType',
  OverrideReasonType = 'OverrideReasonType',
  PaymentType = 'PaymentType',
  PlanItemOutcomeType = 'PlanItemOutcomeType',
  PlanItemStatusType = 'PlanItemStatusType',
  PlanItemType = 'PlanItemType',
  PositionOutcomeType = 'PositionOutcomeType',
  PositionType = 'PositionType',
  PreferredContactType = 'PreferredContactType',
  PrimaryCategory = 'PrimaryCategory',
  PropertyAssignmentReasonType = 'PropertyAssignmentReasonType',
  PropertyAssignmentRecordType = 'PropertyAssignmentRecordType',
  PropertyStatusType = 'PropertyStatusType',
  PropertyType = 'PropertyType',
  PropertyTypeAttribute = 'PropertyTypeAttribute',
  ProvinceType = 'ProvinceType',
  RaceType = 'RaceType',
  RecordType = 'RecordType',
  ReferralOutcomeType = 'ReferralOutcomeType',
  RequestStatusType = 'RequestStatusType',
  RoleType = 'RoleType',
  SecondaryCategory = 'SecondaryCategory',
  SectorType = 'SectorType',
  SelfIdentificationType = 'SelfIdentificationType',
  SiteAttributeType = 'SiteAttributeType',
  StartReasonType = 'StartReasonType',
  SubGoalOutcomeType = 'SubGoalOutcomeType',
  SubGoalStatusType = 'SubGoalStatusType',
  SubGoalType = 'SubGoalType',
  TargetType = 'TargetType',
  TaskExtensionStatusType = 'TaskExtensionStatusType',
  TaskType = 'TaskType'
}